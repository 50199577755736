import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';

import Marketing1 from '../assets/images/iot-features-image/iot-feature-image1.png'
import Marketing2 from "../assets/images/man-and-women.png";

const Marketing = () => (
    <Layout>
        <SEO title="Marketing & Communications" /> 

        <Navbar />

        <div className="features-details-area ptb-80">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 features-details-image">
                        <img 
                            src={Marketing1} 
                            className="animate__animated animate__fadeInUp" 
                            alt="image" 
                        />
                    </div>

                    <div className="col-lg-6 features-details">
                        <div className="features-details-desc">
                            <h3>Marketing & Communications</h3>
                            <p>
                            Our team can help you design, market, and sell your product or service. We leverage content writers, graphic designers, and digital media experts to drive engaging, targeted campaigns, that will attract and retain customers.</p>

                            <ul className="features-details-list">
                                <li>Targeted Ads throughout the Web and Social Media</li>
                                <li>Graphic Design and Content Writing</li>
                                <li>Conversion Funnels</li>
                                <li>End-to-end Marketing Strategy and Execution</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="separate"></div>

                <div className="row align-items-center">
                    <div className="col-lg-6 features-details">
                        <div className="features-details-desc">
                            <p> Faraya's marketing team benefits from having access to the organization's exceptional designers, data scientists, and content writers. We leverage our technical capabilities to drive forward cutting-edge approaches for targeting customers with predictive analytics. </p>

                            <div className="features-details-accordion">
                                <Accordion allowZeroExpanded preExpanded={['a']}>
                                    <AccordionItem uuid="a">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                Where Can You Run Ads?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Leveraging Programmatic Advertising, we can target customers across the full digital ecosystem, including all social media platforms.                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem uuid="b">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                    Can You Produce Video Content?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Video content creation is not a service that we currently provide, however we have media creation partners that we can help you engage with.                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem uuid="c">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                Can You Provide A Sales Team?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Yes, if desired by the client, we can identify and assemble a sales team to support your product or service.                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-6 features-details-image">
                        <img 
                            src={Marketing2} 
                            className="animate__animated animate__fadeInUp" 
                            alt="image" 
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </Layout>
)

export default Marketing;
